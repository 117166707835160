export const LARGE_VIEWPORT = "large-viewport";
export const MEDIUM_VIEWPORT = "medium-viewport";
export const SMALL_VIEWPORT = "small-viewport";
export const MAX_RECENTLY_VIEWED_PRODUCTS = 25;

export const IMAGE_SUFFIXES = {
  carousel: {
    168: "?$n_240w$&wid=168&fit=constrain",
    317: "?$n_320w$&wid=317&fit=constrain",
    476: "?$n_480w$&wid=476&fit=constrain",
    513: "?$n_640w$&wid=513&fit=constrain",
    750: "?$n_750w$&wid=750&fit=constrain",
  },
  hero: {
    317: "?$n_320w$&wid=317&fit=constrain",
    476: "?$n_480w$&wid=476&fit=constrain",
    513: "?$n_640w$&wid=513&fit=constrain",
    750: "?$n_750w$&wid=750&fit=constrain",
    952: "?$n_960w$&wid=952&fit=constrain",
    1125: "?$n_1280w$&wid=1125&fit=constrain",
    1926: "?$n_1920w$&wid=1926&fit=constrain",
  },
  thumbnail: {
    40: "?$n_240w$&wid=40&fit=constrain",
    56: "?$n_240w$&wid=56&fit=constrain",
    75: "?$n_240w$&wid=75&fit=constrain",
    120: "?$n_240w$&wid=120&fit=constrain",
    240: "?$n_240w$&wid=168&fit=constrain",
    317: "?$n_320w$&wid=317&fit=constrain",
  },
  collage: {
    240: "&$n_240w$&wid=240&fit=constrain",
    320: "&$n_320w$&wid=320&fit=constrain",
    480: "&$n_480w$&wid=480&fit=constrain",
    640: "&$n_640w$&wid=640&fit=constrain",
    750: "&$n_750w$&wid=750&fit=constrain",
    960: "&$n_960w$&wid=960&fit=constrain",
    1280: "&$n_1280w$&wid=1280&fit=constrain",
    1920: "&$n_1920w$&wid=1920&fit=constrain",
  },
  card: {
    163: "?$n_240w$&wid=163&fit=constrain",
    218: "?$n_240w$&wid=218&fit=constrain",
    326: "?$n_480w$&wid=326&fit=constrain",
    436: "?$n_480w$&wid=436&fit=constrain",
    489: "?$n_640w$&wid=489&fit=constrain",
    654: "?$n_750w$&wid=654&fit=constrain",
  },
};

export const CAROUSEL_IMAGE_SIZE = 168;

export const PAGE_AREAS = {
  MIGHT_LIKE: "youmightlike",
  LOOKS: "looks",
  LOOKS_SIMILAR_ITEMS: "lookssimilaritems",
  BUY_THE_LOOK: "buythelook",
  RECENTLY_VIEWED: "recentlyviewed",
  OUT_OF_STOCK: "oosproduct",
  MAIN: "main",
  PEOPLE_ALSO_BOUGHT: "peoplealsobought",
};

export const PRODUCT_COLOUR_POSITIONS = {
  TOP: "top",
  MIDDLE: "middle",
};

export const PRODUCT_COLOUR_TYPES = {
  WITHIN_CAROUSEL_FACETS: "withinCarouselFacets",
  DEFAULT: "",
};

export const MODAL_TYPE = {
  CENTER: "centerModal",
  CENTER_LARGE: "centerModalLarge",
  SIDE: "sideModal",
};

export const TEXT_SIZES = {
  london1: "london1",
  london2: "london2",
  london3: "london3",
  london4: "london4",
  london5: "london5",
  leavesden1: "leavesden1",
  leavesden2: "leavesden2",
  leavesden3: "leavesden3",
  leavesden4: "leavesden4",
};

export const VIEW_MORE_OFFSET = 10;
export const VIEW_MORE_ERROR = "viewmoreerror";
export const FILTER_ERROR = "filtererror";
export const RATINGS_API_TIMEOUT = 5000;
export const MIGHT_LIKE_API_LIMIT = 18;

export const PROMISE_PENDING = "PROMISE PENDING";

export const US_STORE = "US";
export const CALIFORNIA_REGION_CODE = "US-CA";
export const PROP_65_URL = "www.P65Warnings.ca.gov";
export const PROP_65_SESSION_KEY = "Asos.PDP.Prop65.Should.Show";

export const INLINE_SPINNER = "inline-spinner";
export const SMALL_SPINNER = "small-spinner";
export const MEDIUM_SPINNER = "medium-spinner";
export const LARGE_SPINNER = "large-spinner";

export const REVIEW_DISCLAIMER_STORES = [
  "COM",
  "DE",
  "FR",
  "ES",
  "IT",
  "PL",
  "DK",
  "SE",
  "RU",
  "ROE",
  "ROW",
  "US",
  "NL",
];

export const LOWEST_PRICE_IN_LAST_30_DAYS_COUNTRIES = [
  "AD",
  "AL",
  "AM",
  "AT",
  "AX",
  "AZ",
  "BA",
  "BE",
  "BG",
  "BL",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FO",
  "FR",
  "GE",
  "GF",
  "GL",
  "GP",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "KG",
  "KZ",
  "LT",
  "LU",
  "LV",
  "MC",
  "MD",
  "ME",
  "MF",
  "MK",
  "MQ",
  "MT",
  "NC",
  "NL",
  "NO",
  "PF",
  "PL",
  "PM",
  "PT",
  "RE",
  "RO",
  "RS",
  "SE",
  "SI",
  "SJ",
  "SK",
  "SM",
  "TF",
  "TR",
  "VA",
  "WF",
  "YT",
];

export const LEGISLATION_API_TIMEOUT = 5000;
export const SUSTAINABILITY_INFO_CONFIG = [
  {
    store: "FR",
    browseLanguage: "fr-FR",
    includedInformation: ["product", "packaging"],
  },
  {
    store: "IT",
    browseLanguage: "it-IT",
    includedInformation: ["packaging", "images"],
  },
];

export const SITE_CHROME_MODALS_URL = "/web/pages/seller-info/index.html";
export const SIMPLE_MODAL_URL = "web/pages/simple-modal/index.html";

export const LOCAL_STORAGE_KEY_BACK_IN_STOCK_BANNER_DISMISSED =
  "Asos.ProductPage.BackInStockBannerDismissed";

export const VARIANT_TYPE = {
  size: "size",
  colour: "colour",
};

export const customerPreferenceCookie = "C0003";
export const fitAssistantLoggingPercent = 5;

export const FACET_PRODUCT_SIZE_SELECTION =
  "asos-pdp-lastFacetedProductSizeSelected";

export const BACK_IN_STOCK_BUTTON_ID = "back-in-stock-button";
export const BACK_IN_STOCK_API_TIMEOUT = 5000;
export const BACK_IN_STOCK_VARIANT_IDS_SESSION_KEY =
  "Asos.ProductPage.BackInStockVariantIds";
export const BACK_IN_STOCK_SELECTED_VARIANT =
  "Asos.ProductPage.BackInStockSelectedVariant";

export const BACK_IN_STOCK_NOTIFY_MODAL_COPY_KEY = "NOTIFY_ME_MODAL_COPY_KEY";
export const BACK_IN_STOCK_TURN_OFF_ALERT_MODAL_COPY_KEY =
  "TURN_OFF_ALERT_MODAL_COPY_KEY";

export const BACK_IN_STOCK_SELECTED_PRODUCT =
  "Asos.ProductPage.BackInStockSelectedProduct";

export const GET = "get";
export const PUT = "put";
export const DELETE = "delete";
export const POST = "post";

export const PRODUCT_PROMO_LIVE_STORES = [
  "FR",
  "ROE",
  "AU",
  "DK",
  "ES",
  "IT",
  "NL",
  "PL",
  "RU",
  "SE",
  "COM",
  "DE",
  "US",
  "ROW",
];

export const LOOKS_RECOMMENDATIONS_HEADER = "pdp_ymal_btl";

export const REPORT_LEGAL_CONCERN_LINK_PATH =
  "/concerns/report-a-legal-concern";

export const NOTIFICATION_DRAWER_ID = "notificationDrawer";

export const CUSTOMER_ACCESS_VIEW = "view";
export const CUSTOMER_ACCESS_PURCHASE = "purchase";
export const CUSTOMER_ACCESS_NONE = "none";

export const BULLET_SYMBOL = " \u2022 ";
